import $ from 'jquery';
import _ from 'lodash';

import Stream from '../libs/Stream';
import data from '../data';
import config from '../config';
import {Accessories, AccessoryCategories, ColorCategories, Colors, Glasses, InnerWindowsillColors, InnerWindowsillSizes, Materials, OuterWindowsillColors, OuterWindowsillSizes, SunblindColors, SunblindSizes, WindowRecommendations, WindowSizes, WindowTypes,} from '../types/data';

const ONE_CZK_IN_EUR = config.ONE_CZK_IN_EUR;

export interface DataStreamValue {
	windowTypes: WindowTypes;
	windowSizes: WindowSizes;
	windowRecommendations: WindowRecommendations;
	materials: Materials;
	glasses: Glasses;
	colors: Colors;
	colorCategories: ColorCategories;
	sunblindSizes: SunblindSizes;
	sunblindColors: SunblindColors;
	innerWindowsillSizes: InnerWindowsillSizes;
	innerWindowsillColors: InnerWindowsillColors;
	outerWindowsillSizes: OuterWindowsillSizes;
	outerWindowsillColors: OuterWindowsillColors;
	accessories: Accessories;
	accessoryCategories: AccessoryCategories;
	// ONE_CZK_IN_EUR: number;
}

let dataStream: Stream<DataStreamValue> = new Stream({
	prices: data.prices,
	windowTypes: data.windowTypes,
	windowSizes: data.windowSizes,
	windowRecommendations: data.windowRecommendations,
	materials: data.materials,
	glasses: data.glasses,
	colors: data.colors,
	colorCategories: data.colorCategories,
	sunblindSizes: data.sunblindSizes,
	sunblindColors: data.sunblindColors,
	innerWindowsillSizes: data.innerWindowsillSizes,
	innerWindowsillColors: data.innerWindowsillColors,
	outerWindowsillSizes: data.outerWindowsillSizes,
	outerWindowsillColors: data.outerWindowsillColors,
	accessories: [],
	accessoryCategories: [],
	// ONE_CZK_IN_EUR
}) as Stream<DataStreamValue>;

dataStream.push(
	(async () => {
		let response;
		
		let subdomain = 'www';
		if (window.location.hostname === 'stage.kalkulator-zaluzii.cz') {
			subdomain = 'test:test@test';
		}
		
		try {
			let productListPath = `https://${subdomain}.okna-hned.cz/scripts/export-products-list/products-list-configurator-json.json`;
			if (process.env.NODE_ENV !== 'production') {
				productListPath = '/scripts/ProductsListConfiguratorJson.json';
			}
			
			response = await Promise.resolve($.getJSON(productListPath));
		} catch (error) {
			console.error(error);
			
			return dataStream.value;
		}
		
		let accessories = response.products;
		let accessoryCategories = accessories.map((accessory) => ({
			id: accessory.categoryId,
			name: accessory.categoryName,
		}));
		
		accessoryCategories = _.uniqWith(accessoryCategories, _.isEqual);
		
		try {
			response = await Promise.resolve($.getJSON(`https://${subdomain}.okna-hned.cz/scripts/getExRate.php`));
		} catch (error) {
			console.error(error);
		}
		config.ONE_CZK_IN_EUR = parseFloat(response.data[0].conversion);
		
		try {
			response = await Promise.resolve($.getJSON(`https://${subdomain}.okna-hned.cz/scripts/getExRateFt.php`));
		} catch (error) {
			console.error(error);
		}
		config.ONE_CZK_IN_HUF = parseFloat(response.data[0].conversion);
		
		// console.log(parseFloat(response.data[0].conversion));
		
		return {
			prices: data.prices,
			windowTypes: data.windowTypes,
			windowSizes: data.windowSizes,
			windowRecommendations: data.windowRecommendations,
			materials: data.materials,
			glasses: data.glasses,
			colors: data.colors,
			colorCategories: data.colorCategories,
			sunblindSizes: data.sunblindSizes,
			sunblindColors: data.sunblindColors,
			innerWindowsillSizes: data.innerWindowsillSizes,
			innerWindowsillColors: data.innerWindowsillColors,
			outerWindowsillSizes: data.outerWindowsillSizes,
			outerWindowsillColors: data.outerWindowsillColors,
			accessories,
			accessoryCategories,
			// ONE_CZK_IN_EUR: parseFloat(response.data[0].conversion)
		};
	})(),
);

(global as any).dataStream = dataStream;

export default dataStream;
